import {stage} from './stage';

const platform = {
    prod: {
        portal: 'https://portal.flexi.network',
        console: 'https://console.flexi.network',
        domains: 'https://www.flexi.domains',
        email: 'https://www.flexi.email',
        bot: 'https://www.flexi.bot',
    },
    test: {
        portal: 'https://portal.test.flexi.network',
        console: 'https://console.test.flexi.network',
        domains: 'https://test.flexi.domains',
        email: 'https://test.flexi.email',
        bot: 'https://test.flexi.bot',
    },
    dev: {
        portal: 'https://portal.dev.flexi.network',
        console: 'https://console.dev.flexi.network',
        domains: 'https://dev.flexi.domains',
        email: 'https://dev.flexi.email',
        bot: 'https://dev.flexi.bot',
    },
    ide: {
        portal: 'https://localhost:8011',
        console: 'https://localhost:8022',
        domains: 'https://localhost:8021',
        email: 'https://localhost:8023',
        bot: 'https://localhost:8022',
    },
};

export default platform[stage];
