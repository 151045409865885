import {
    Anchor,
    Box,
    Center,
    Container,
    Grid,
    Group,
    Image,
    Paper,
    Stack,
    Text,
    Title
} from "@mantine/core";

import {applications} from "./data";
import {config} from "../../../config";

import {AiOutlineLink} from "react-icons/ai";
import {FlexiSignInButton} from "@flexinet/ui-components";

const ApplicationLogoAnchor = ({application}: { application: { url: string, name: string, logo: string } }) => {
    return (
        <Anchor href={application.url} target="_blank">
            <Image src={application.logo} alt={application.name}/>
        </Anchor>
    )
}

const TitleAndDescription = ({application}: { application: { url: string, name: string, description: string } }) => {
    return (
        <>
            <Box style={{marginBottom: 'var(--mantine-spacing-lg)'}}>
                <Title order={3}
                       style={{color: 'var(--mantine-color-dark-7)'}}>
                    <Group>
                        <Text tt={'uppercase'}>{application.name}</Text>
                        <Anchor href={application.url} target="_blank">
                            <AiOutlineLink/>
                        </Anchor>
                    </Group>
                </Title>
            </Box>
            <Box>
                <Text>{application.description}</Text>
            </Box>
        </>
    )
}

// application tile component
export const ApplicationTile = ({
                                    application
                                }: {
    application: { name: string, description: string, logo: string, url: string, position: string }
}) => {

    switch (application.position) {
        case 'left':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid style={{padding: 'var(--mantine-spacing-xl)'}}>
                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '1em'
                        }}>
                            <ApplicationLogoAnchor application={application}/>
                        </Grid.Col>

                        <Grid.Col span={8}>
                            <TitleAndDescription application={application}/>
                        </Grid.Col>

                    </Grid>
                </Paper>
            )
        case 'right':
            return (
                <Paper shadow="xl" p="xl" withBorder>
                    <Grid style={{padding: 'var(--mantine-spacing-xl)'}}>

                        <Grid.Col span={8}>
                            <TitleAndDescription application={application}/>
                        </Grid.Col>

                        <Grid.Col span={4} style={{
                            textAlign: 'center',
                            marginTop: '1em'
                        }}>
                            <ApplicationLogoAnchor application={application}/>
                        </Grid.Col>
                    </Grid>
                </Paper>
            )
    }

}


export const IndexPage = () => {

    const activeApplications = applications.filter(item => item.active === true)


    return (
        <Container>

            <Stack style={{marginTop: 'var(--mantine-spacing-xl)'}}>
                {activeApplications.map(application => {
                    return <ApplicationTile application={application}/>
                })}
            </Stack>


            <Center>
                <Stack gap={"xl"} my={'xl'}>
                    <Group justify={'center'}>

                        <FlexiSignInButton
                            size={'xl'}
                            onClick={() => window.location.assign(config.platform.portal + '/sign-in')}/>

                    </Group>
                </Stack>
            </Center>
        </Container>

    );
};
