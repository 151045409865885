import {PlatformApplication} from "../types.ts";

export const marketingApp = {
    name: "Flexi Network",
    url: "https://www.flexi.network",
    logo:
        "https://s3.amazonaws.com/cdn.flexi.network/flexi.network/images/logo/logo.png",
    description: "Innovative Software",
} as PlatformApplication;

export const applications = [
    {
        active: true,
        name: "Flexi Domains",
        url: "https://www.flexi.domains",
        logo:
            "https://s3.amazonaws.com/cdn.flexi.network/flexi.domains/images/logo/flexidomains_logo.png",
        description: "Manage your domain portfolio across multiple registrars. Track registrations, expirations, cost and transactions",
        position: "left",
    },
    {
        active: true,
        name: "Flexi Email",
        url: "https://www.flexi.email",
        logo:
            "https://s3.amazonaws.com/cdn.flexi.network/flexi.email/images/logo/logo.png",
        description:
            "Create multiple aliases. Receive and organize email. Track deliveries",
        position: "right",
    },
    {
        active: true,
        name: "Flexi Bot",
        url: "https://www.flexi.bot",
        logo:
            "https://s3.amazonaws.com/cdn.flexi.network/flexi.bot/images/logo/logo.png",
        description: "Discover your favorite books and authors.Chat and query based on genre, author, title",
        position: "left",
    },
    {
        active: false,
        name: "Flexi Ebooks",
        url: "https://www.flexiebooks.com",
        logo:
            "https://s3.amazonaws.com/cdn.flexi.network/flexiebooks.com/images/logo/flexiebooks_logo.png",
        description:
            "Search and find your favorite books, ebooks and audiobooks. Discover new titles.Create virtual bookshelves for your books. A fun way to find great books.",
        position: 'right'
    },
];
