import {AppLayout} from "./layout/AppLayout";

import {IndexPage} from "./features/home/pages/IndexPage";
import {PrivacyPage} from "./features/home/pages/PrivacyPage";

import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";


import '@mantine/core/styles.css';
import {MantineProvider} from "@mantine/core";
import {theme} from "./theme.ts";

import {TermsOfServicePage} from "./features/home/pages/TermsOfServicePage.tsx";

const router = createBrowserRouter([{
    path: '/',
    element: <AppLayout/>,
    children: [{
        index: true,
        element: <IndexPage/>
    }, {
        path: 'privacy',
        element: <PrivacyPage
            application={{
                name: "Flexi Network",
                url: "flexi.network"
            }}/>
    }, {
        path: 'terms-of-service',
        element: <TermsOfServicePage
            application={{
                name: "Flexi Network",
                url: "https://www.flexi.network",
                email: "support@flexi.network"
            }}/>
    }]
}])


function App() {

    return (
        <MantineProvider theme={theme}>
            <RouterProvider router={router}/>
        </MantineProvider>
    )
}

export default App
