import {Outlet} from 'react-router-dom';


import {
    FlexiSignInButton,
    PlatformHeader,
    PlatformHeaderNavigationMenu,
    PlatformHeaderToolbar,
    PlatformLoader,
    PlatformPublicAppShell
} from '@flexinet/ui-components';

import {Suspense} from "react";
import {config} from "../config";


export const AppLayout = () => {

    const menuItems = [] as { title: string, onClick: () => void }[]
    // menuItems.push({
    //                    title: 'Home',
    //                    onClick: () => navigate('/')
    //                })


    return <PlatformPublicAppShell
        platformHeader={<PlatformHeader
            navigationMenu={<PlatformHeaderNavigationMenu menuItems={menuItems}/>}
            toolbar={<PlatformHeaderToolbar additionalToolbarItems={[
                <FlexiSignInButton
                    onClick={() => window.location.assign(config.platform.portal + '/sign-in')}>Sign
                    in</FlexiSignInButton>,
            ]}/>}/>}>
        <Suspense fallback={<PlatformLoader/>}>
            <Outlet/>
        </Suspense>
    </PlatformPublicAppShell>


};
